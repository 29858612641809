<template>
  <div class="plate-number">
    <van-field label="车牌号码"
               :rules="[{ required: true, pattern, message: '请输入正确的车牌'}]"
               :required="required"
               class="to-upper"
               @blur="onChange"
               oninput="value=value.toUpperCase().replace(/\s/g, '')"
               name="plateNumber"
               v-model="plateNumber"
               placeholder="请输入车牌号码" />
  </div>
</template>

<script>
  import * as common from '../api/common'
  export default {
    name: "plate-number",
    props: ['required', 'value'],
    data () {
      return {
        show: false,
        pattern: new RegExp('^[' +  common.plateNumberHeader.join('') + '][A-Za-z][a-zA-Z0-9]{5,6}(学|使)?$'),
        plateNumber: ''
      }
    },
    watch: {
      value: function (newVal) {
        if (!newVal) {
          this.getPrefix()
        }
      },
      plateNumber: function (newVal) {
        this.$emit('update:value', newVal)
      }
    },
    methods: {
      onChange (e) {
        let event = e.srcElement || e.target
        if (event) {
          let val = event.value
          if (val) {
            let v = val.replace(/\s/g, '')
            this.plateNumber = v.toUpperCase()
            if (v) {
              localStorage.setItem('tempPlateNumberPrefix', this.plateNumber.substr(0, 2))
            }
          }
        } else {
          this.getPrefix ()
        }
      },
      getPrefix () {
        let prefix = localStorage.getItem('tempPlateNumberPrefix')
        if (prefix) {
          this.plateNumber = prefix
        }
      }
    },
    mounted () {
      if (!this.value) {
        this.getPrefix()
      }
    }
  }
</script>

<style scoped lang="scss">
@import "../theme/index";
.plate-number{
  display: flex;
  font-size: $global-font-size-X-large;
  font-weight: bold;
  align-items: center;
  justify-content: flex-start;
  color: $global-theme-black;
}

</style>