<template>
  <div class="vh-type">
    <van-field readonly :required="required" input-align="right" is-link
               :rules="required ? [{ required: true}] : []"
               @focus="show = true" v-model="txt"
               name="vhTypeTxt"
               label="车辆类型" placeholder="请选择车辆类型" />
    <van-popup v-model:show="show" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="onCancel"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
  export default {
    name: "vh-type",
    props: ['required', 'value'],
    data () {
      return {
        show: false,
        columns: [
          {
            text: '蓝牌车',
            id: 1,
          },
          {
            text: '军警车',
            id: 2,
          },
          {
            text: '黄牌车',
            id: 3,
          },
          {
            text: '新能源小车',
            id: 4,
          },
          {
            text: '新能源大车',
            id: 5,
          },
          {
            text: '使馆车',
            id: 6,
          },
          {
            text: '港澳车',
            id: 7,
          },
          {
            text: '其他',
            id: 8,
          }
        ]
        // ,
        // txt: ''
      }
    },
    computed: {
      txt: {
        set() {},
        get() {
          console.log('this.value vh_type', this.value)
          if (this.value) {
            return this.setDefault (this.value)
          } else {
            return this.setDefault (1)
          }
        }
      }
    },
    methods: {
      onConfirm (values) {
        console.log(values)
        // this.txt = values.text
        this.$emit('update:value', values.id)
        this.show = false
      },
      onCancel (values) {
        console.log(values)
        // this.txt = ''
        // this.$emit('update:value', '')
        // this.setDefault (1)
        this.show = false
      },
      setDefault (val) {
        let txt = ''
        this.columns.forEach((item) => {
          if (val == item.id) {
            txt = item.text
            this.$emit('update:value', item.id)
          }
        })
        return txt
      }
    },
    mounted () {
      this.setDefault (1)
    }
  }
</script>

<style scoped lang="scss">
@import "../theme/index";
.vh-type{
  display: flex;
  font-size: $global-font-size-X-large;
  font-weight: bold;
  align-items: center;
  justify-content: flex-start;
  color: $global-theme-black;
}

</style>